<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">{{pageTitle}}</a>
  </div>
</div>
<div class="header-container username">
  <b>{{user}}</b>{{userRole}}
</div>
<div class="header-container">
  <a class="action-link" href="http://action.ru/">
    <svg class="action-logo" viewBox="0 0 240 172.52">
      <defs>
        <style>
          .a {
            fill: #e52d32;
          }

          .b {
            fill: #fff;
          }
        </style>
      </defs>
      <polygon class="a" points="0 172.52 0 25.59 25.59 0 240 0 240 172.52 0 172.52" />
      <path class="b"
        d="M75.89,57.17l-.09-.45a32.72,32.72,0,0,1-.39-6l.09-9.63c0-3.7-.38-6.19-1.16-7.61a8.65,8.65,0,0,0-4-3.52c-1.86-.9-4.69-1.36-8.43-1.36-4.12,0-7.67.9-10,2.52a11.29,11.29,0,0,0-4.56,8.22v.5l8.92-.05.11-.33a5.49,5.49,0,0,1,1.82-2.79,5.41,5.41,0,0,1,3.15-.78c2,0,3.42.3,4.11.89a3.84,3.84,0,0,1,1,3v.49A63.4,63.4,0,0,1,59.64,42l-.84.18-1.16.25A37.32,37.32,0,0,0,51,44.27a8.81,8.81,0,0,0-3.74,3.35,9.43,9.43,0,0,0-1.33,5,9.2,9.2,0,0,0,2.93,7c2.07,2,6,2.64,9.09,2.64a13.63,13.63,0,0,0,5.33-1.06,14.86,14.86,0,0,0,4-2.57,19.53,19.53,0,0,0,.53,2.62l.12.31H77l-.22-.65A28.06,28.06,0,0,1,75.89,57.17ZM66.42,46.7v1a16.17,16.17,0,0,1-.3,3.88A4.68,4.68,0,0,1,64.34,54a10.41,10.41,0,0,1-5.15,1.37,4,4,0,0,1-2.94-1.12,3.52,3.52,0,0,1-1.14-2.64,2.93,2.93,0,0,1,1.4-2.5,21.34,21.34,0,0,1,4.2-1.1L62,47.77C63.87,47.37,65.36,47,66.42,46.7Z" />
      <path class="b"
        d="M94.3,36.1a5.21,5.21,0,0,1,3.46,1.12,5.46,5.46,0,0,1,1.69,3.38l.07.4,9.49.06-.09-.57a15.16,15.16,0,0,0-5.4-9.31c-2.31-1.68-5.45-2.54-9.34-2.54-4.75,0-8.58,1.5-11.39,4.45s-4.21,7.11-4.21,12.36,1.41,9.32,4.2,12.28S89.36,62.19,94,62.19c4.1,0,7.43-1,9.9-2.95a14.15,14.15,0,0,0,5-10l0-.5-9-.14-.08.4a7.54,7.54,0,0,1-2,4.32,5,5,0,0,1-3.52,1.21,5.64,5.64,0,0,1-4.63-2.1C88.63,51,88,48.42,88,44.86c0-3.19.58-5.48,1.72-6.8A5.66,5.66,0,0,1,94.3,36.1Z" />
      <path class="b"
        d="M129.1,54.46c-1.49,0-3.09,0-3.86,0a2.15,2.15,0,0,1-1.19-.36,1.37,1.37,0,0,1-.54-.77,27.13,27.13,0,0,1-.15-4.32V35.72H129V29.34h-5.64V20.11l-9.25.1v9.13h-5.38v6.38h5.38V50a40.48,40.48,0,0,0,.27,5.9,6.29,6.29,0,0,0,1,3,4.85,4.85,0,0,0,2.47,2,14.39,14.39,0,0,0,4.84.7h6.43Z" />
      <rect class="b" x="132.47" y="17.49" width="9.22" height="7.53" />
      <rect class="b" x="132.47" y="29.34" width="9.22" height="32.14" />
      <path class="b"
        d="M161.06,28.64a17.48,17.48,0,0,0-8.52,2.09,14.43,14.43,0,0,0-5.94,6.07A17.41,17.41,0,0,0,144.52,45a19.5,19.5,0,0,0,2.08,9.34,14,14,0,0,0,6.13,5.89,18.78,18.78,0,0,0,8.39,2A16,16,0,0,0,173,57.4a16.63,16.63,0,0,0,4.7-12.07A16.41,16.41,0,0,0,173,33.38,16.11,16.11,0,0,0,161.06,28.64Zm7.14,16.72c0,3-.69,5.29-2.06,6.83a6.71,6.71,0,0,1-10.11,0c-1.38-1.54-2.08-3.82-2.08-6.77s.7-5.24,2.08-6.78a6.71,6.71,0,0,1,10.11,0C167.51,40.18,168.2,42.44,168.2,45.36Z" />
      <path class="b"
        d="M209.06,36a9.6,9.6,0,0,0-1.7-3.64,9,9,0,0,0-3.61-2.67,13.09,13.09,0,0,0-5.19-1,12.77,12.77,0,0,0-9.78,4.42V29.34h-8.64V61.49h9.23V46.88a26.23,26.23,0,0,1,.61-7A5.36,5.36,0,0,1,192.12,37a6.12,6.12,0,0,1,3.57-1.09,4.5,4.5,0,0,1,2.58.73,4.11,4.11,0,0,1,1.53,2.07,24.07,24.07,0,0,1,.5,6.36v16.4h9.23V41.63A26.42,26.42,0,0,0,209.06,36Z" />
    </svg>
  </a>
</div>
