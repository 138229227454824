 <ng-template #posmTemplate let-items="items">
  <br>
  <span class="route-sub-title">POSM</span>
  <div *ngFor="let item of items; let last = last">
    <span class="route-title">{{item.posm.name}}({{item.quantity}} шт.)</span>
  </div>
 </ng-template>

<nb-card nbSpinnerStatus="primary">
  <nb-card-header>Маршрут</nb-card-header>
  <nb-card-body>
    <ng-container *ngIf="route$ | async as route;">
      <div [class.container]="isModal">
        <div *ngIf="route?.parts.length === 0" class="row">
        <div class="col-12">
          <h6>У вас нет маршрута</h6>
        </div>
      </div>
      <div *ngFor="let routePart of route.parts; let i = index" class="row">
        <div class="col-2 col-md-1 value">
          <span class="value-item">{{letters[i]}}</span>
        </div>
        <div class="col-10 col-md-11">
          <h6 class="title">Заявка {{routePart.order.id}}</h6>

          <div *ngIf="routePart.discr === 'route_part_take_from_warehouse'" class="content">
            <span class="route-sub-title">Склад: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.warehouse.name}}</span>

            <div>{{routePart.warehouse.address_name}}</div>

            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_store_in_store'" class="content">
            <span class="route-sub-title">Торговая точка: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.store.name}}</span>

            <div>{{routePart.store.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_repair_in_store'" class="content">
            <span class="route-sub-title">Торговая точка: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.store.name}}</span>

            <div>{{routePart.store.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_take_from_store'" class="content">
            <span class="route-sub-title">Торговая точка: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.store.name}}</span>

            <div>{{routePart.store.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_store_in_warehouse'" class="content">
            <span class="route-sub-title">Склад: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.warehouse.name}}</span>

            <div>{{routePart.warehouse.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_delivery_take_from_store'" class="content">
            <span class="route-sub-title route-sub-title--bold">Торговая точка: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.store.name}}</span>

            <div>{{routePart.store.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_delivery_take_from_warehouse'" class="content">
            <span class="route-sub-title route-sub-title--bold">Склад: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.warehouse.name}}</span>

            <div>{{routePart.warehouse.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_delivery_store_in_store'" class="content">
            <span class="route-sub-title route-sub-title--bold">Торговая точка: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.store.name}}</span>

            <div>{{routePart.store.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

          <div *ngIf="routePart.discr === 'route_part_delivery_store_in_warehouse'" class="content">
            <span class="route-sub-title route-sub-title--bold">Склад: {{tranlsate[routePart.discr]}}</span>
            <span class="route-title">{{routePart.warehouse.name}}</span>

            <div>{{routePart.warehouse.address_name}}</div>
            <div *ngIf="routePart.bag && routePart.bag.items && routePart.bag.items.length > 0">
              <ng-container
                *ngTemplateOutlet="posmTemplate;context:{ items: routePart.bag.items }"
              ></ng-container>
            </div>

            <br>
            <span class="route-sub-title">Исполнитель</span>
            <span class="route-title">{{routePart?.installer?.first_name}} {{routePart?.installer?.last_name}}</span>

            <div *ngIf="routePart.completed" class="success">
              Выполнено!
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="isModal" class="form-group">
        <label for="date" class="label">Укажите дату*</label>
        <input
          id="date"
          type="text"
          nbInput
          fullWidth
          placeholder="Дата"
          [(ngModel)]="modelDate"
          (ngModelChange)="handleDateChange($event)"
          [nbDatepicker]="routePicker"
        />
        <nb-datepicker
          format="DD.MM.YYYY"
          #routePicker>
        </nb-datepicker>
        <div class="error" *ngIf="dateErrorMessage">
          {{dateErrorMessage}}
        </div>
      </div>
      <button
        *ngIf="isModal"
        (click)="handleAccept()"
        nbButton
        class="button"
        size="small">
          Принять
        </button>
      <div>
        <button
          *ngIf="isModal"
          (click)="handleDecline()"
          nbButton
          class="button"
          status="info"
          size="small"
        >
          Отклонить
        </button>
      </div>
      </div>
    </ng-container>
  </nb-card-body>
</nb-card>
