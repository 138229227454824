import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  constructor(
    private http: HttpClient
  ) { }

  create<T>(payload: T, url: string) {
    return this.http.post(`${API_URL}${url}`, payload);
  }

  get<T>(url: string, params?: string) {
    if (params) {
      return this.http.get<T>(`${API_URL}${url}?${params}`);
    }

    return this.http.get<T>(`${API_URL}${url}`);
  }

  update<T>(payload: T, url: string) {
    return this.http.put(`${API_URL}${url}`, payload);
  }

  delete<T>(payload: T, url: string) {
    return this.http.delete(`${API_URL}${url}`);
  }

  sendPost<T>(url: string, payload = {}): Observable<T> {
    return this.http.post<T>(`${API_URL}${url}`, payload);
  }
}
