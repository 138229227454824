import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CrudService } from '../../../../services/crud/crud.service';
import { BlankRunPayload } from '../../../../../types';


@Component({
  templateUrl: './blank-run.component.html',
  styleUrls: ['./blank-run.component.scss']
})
export class BlankRunComponent {
  public bagId: number;
  public comment: string = '';

  constructor(
    protected dialogRef: NbDialogRef<BlankRunComponent>,
    private crudService: CrudService,
  ) { }

  public save(): void {
    const payload: BlankRunPayload = {
      posm_bag_item_id: this.bagId,
      comment: this.comment,
    }
    this.crudService.sendPost('/routes/blank-run', payload)
      .subscribe(() =>  this.dialogRef.close(true))
  }

  public close(): void {
    this.dialogRef.close()
  }
}
