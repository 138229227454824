import { Component, Input } from '@angular/core';
import { StoresResponse } from '../../../../../types';

@Component({
  selector: 'ngx-address-cell',
  template: `
  <div><strong>{{value.name}}</strong></div>
  {{value.address_name}}
  `
})
export class AddressCellComponent {
  @Input() value: StoresResponse;
}
