import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public appVersion: string;
  constructor(
    private http: HttpClient
  ) { }

  readVersion(): Observable<any>{
    return this.createRequest('build.txt' + '?t=' + new Date().getTime()).pipe(
      map((result) => {
        console.log('version', result);
        if (this.appVersion !== undefined && this.appVersion !== result) {
          console.log(`Version has been changed! Old version = ${this.appVersion}, new version ${result}`);

          window.location.reload(true);
        }
        this.appVersion = result;
        return result;
      }),
      catchError((error: HttpErrorResponse) => {
        this.appVersion = 'no version';
        return throwError(error || 'backend server error');
      })
    );
  }
  createRequest(url: string, headers?: object, params?: object): Observable<string> {
    let requestParams = new HttpParams();
    let requestHeaders = new HttpHeaders();

    if (headers) {
      for (const key of Object.keys(headers)) {
        requestHeaders = requestHeaders.set(key, headers[key] + '');
      }
    }

    if (params) {
      for (const key of Object.keys(params)) {
        requestParams = requestParams.set(key, params[key]);
      }
    }

    return this.http.get<string>(url, {
      headers: requestHeaders,
      params: requestParams,
      responseType: 'json'
    });
  }
}
