export function getPartType(routeDiscr: string): EPartType {
  let type: EPartType

  switch (routeDiscr) {
    case 'route_part_store_in_warehouse':
    case 'route_part_delivery_store_in_warehouse':
    case 'route_part_delivery_store_in_store':
      type = EPartType.inDelivery
      break
    case 'route_part_delivery_take_from_store':
    case 'route_part_delivery_take_from_warehouse':
    case 'route_part_take_from_warehouse':
      type = EPartType.outDelivery
      break
    case 'route_part_store_in_store':
      type = EPartType.install
      break
    case 'route_part_take_from_store':
      type = EPartType.uninstall
      break
    case 'route_part_repair_in_store':
      type = EPartType.repair
      break
  }

  return type
}


export enum EPartType {
  'inDelivery' = 0,
  'outDelivery',
  'install',
  'uninstall',
  'repair',
}
