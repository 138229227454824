import { Component } from '@angular/core';
import { BaseStoreFormComponent } from './base-stores-form.component';
import { ServerResponse } from '../../../../types';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-stores-form',
  templateUrl: './stores-form.component.html',
  styleUrls: ['./stores-form.component.scss'],
})
export class StoresFormComponent extends BaseStoreFormComponent {
  handleSuccess = response => {

    if (response) {
      this.toastr.show('Сохранена!', 'Торговая точка', { status: 'primary'});

      this.router.navigateByUrl('pages/stores/list');
    }

    this.isLoading = false;
  }
}
