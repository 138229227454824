<nb-card>
  <nb-card-header>
    Оставьте комментарий к холостому выезду
  </nb-card-header>
  <nb-card-body>
    <div class="wrapper">
      <div class="form-group">
        <label for="comment" class="label" requiredTag>Комментарий</label>
        <textarea
          id="comment"
          nbInput
          fullWidth
          [(ngModel)]="comment"
          placeholder="Напишите комментарий"
        ></textarea>
      </div>
      <div class="actions">
        <button [disabled]="comment?.trim().length == 0" nbButton status="danger" (click)="save()">Отправить</button>
        <button nbButton status="info" (click)="close()">Отмена</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
