<nb-card [nbSpinner]="isLoading" nbSpinnerStatus="primary">
  <nb-card-header>
    <div style="display:inline-block;" [class.delivery]="partType === EPartType.outDelivery || partType === EPartType.inDelivery">
      {{ getPartName[currentPart?.discr] }}
    </div>
    <nb-icon *ngIf="modalId"
             (click)="closeModal()"
             class="close-modal"
             icon="close-outline"
             pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="currentPart?.discr" class="col-sm-12 col-md-12 p-0">
      <div *ngIf="currentPart?.order?.creator_comment" class="content">
        <b><span class="route-sub-title">Инициатор оставил комментарий к заявке:</span></b>

        <p class="route-title">
          {{ currentPart.order.creator_comment }}
        </p>
      </div>
      <div *ngIf="currentPart.execution_comment" class="content">

        <b><span class="route-sub-title">Комментарий старшего установщика:</span></b>

        <p class="route-title">
          {{ currentPart.execution_comment }}
        </p>
      </div>
      <div *ngIf="currentPart?.order?.creator_contact_information" class="content">

        <b><span class="route-sub-title">Контакты инициатора:</span></b>

        <p class="route-title">
          {{ currentPart.order.creator_contact_information }}
        </p>
      </div>
      <div *ngIf="currentPart?.order?.company_representative_contact_information" class="content">

        <b><span class="route-sub-title">Контакты представителя компании:</span></b>

        <p class="route-title">
          {{ currentPart.order.company_representative_contact_information }}
        </p>
      </div>

      <h6 class="mb-0">Заявка ID {{ currentPart.order?.id }}</h6>

      <div class="form-group">
        <div class="label">Выполнить до</div>
        <p class="mb-0">{{ currentPart.order?.deadline }}</p>
      </div>

      <div *ngIf="currentPart.order?.program" class="form-group">
        <div class="label">Программа</div>
        <p class="mb-0">
          {{ currentPart.order.program.name ? currentPart.order.program.name : 'Программа отсутствует' }}
        </p>
      </div>

      <div class="form-group">
        <ng-container
          *ngTemplateOutlet="partType === partTypeEnum.install || partType === partTypeEnum.uninstall ? store : delivery"></ng-container>

        <ng-template #store>
          <div class="label">Торговая точка</div>
          <div><strong>{{ entityCodes }}{{ currentPart.store?.name }}</strong></div>
          <p>{{ currentPart.store?.address_name }}</p>
        </ng-template>

        <ng-template #delivery>
          <label class="label">{{ currentPart['warehouse'] ? "Склад" : "Торговая точка" }}</label>
          <div><strong>{{ entityCodes }}{{ title }}</strong></div>
          <p>{{ address }}</p>
        </ng-template>
      </div>

      <hr/>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label class="label posm mb-2">Список POSM</label>
          <div formArrayName="posmsFields" class="posm-item col-12">
            <div
              *ngFor="let posm of posms; let i = index;"
              class="item-row row"
              [formGroupName]="i"
            >
              <div *ngIf="posm.images" class="posm-item col-md-2 p-0">
                <div class="form-group">
                  <div class="item-name">
                    <img *ngIf="posm.images.length > 0" class="image" src="{{posm.images[0].id | getSrc}}"/>
                    <img *ngIf="posm.images.length === 0" class="image" src="/assets/images/nophoto.jpg"/>
                  </div>
                </div>
              </div>
              <div class="posm-item col-md-3">
                <div class="form-group">
                  <label class="label">Название</label>
                  <div class="posm-value">
                    {{ posm.name }}
                  </div>
                </div>
              </div>
              <div class="posm-item col-md-2">
                <div class="form-group">
                  <label class="label">{{ actionNames[partType] }}</label>
                  <div class="posm-value">
                    {{ posm.count }} шт.
                  </div>
                </div>
                <div *ngIf="isBlankRun(posm.id)" class="form-group">
                  <span style="color: red;">
                      Холостой выезд!
                  </span>
                </div>
              </div>
              <div *ngIf="!isBlankRun(posm.id)" class="posm-item col-md-2">
                <div class="form-group">
                  <label [for]="'count' + i" class="label">Фактически {{ getTypeFactVerb[partType] }}</label>
                  <input [id]="'count_' + i"
                         class="input-number"
                         formControlName="count"
                         type="number"
                         nbInput
                         fullWidth
                         [readonly]="isBlankRun(posm.id)"
                         placeholder="Количество в шт"
                  />
                </div>
              </div>
              <div *ngIf="authSvc.isInstaller && !isBlankRun(posm.id)" class="posm-item col-md-2"
                   style="margin-bottom: 10px;">
                <button nbButton
                        type="button"
                        (click)="setBlankRun(posm)"
                        status="info"
                >Холостой выезд
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-2">
          <button nbButton class="photo-button" status="info" outline type="button" size="small">
            <label (click)="uploadFileWrapper()" class="label-upload" requiredTag>
              Сфотографировать
            </label>
          </button>
          <input
            #openFile
            class="file"
            placeholder="Загрузите файлы"
            (change)="onFileChange($event)"
            type="file"
          />
          <div class="row">
            <div *ngIf="hasUploadedFiles" class="col-12 col-md-8 file-list">
              <div *ngFor="let file of files | keyvalue" class="file-row label">
                <img class="image" src="{{file.key | getSrc}}"/>
                <button
                  nbButton
                  outline
                  status="danger"
                  size="tiny"
                  (click)="removeFile(file.key)"
                >
                  <span class="text">Удалить</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-4">
          <div *ngIf="posmsValuesNotMatch.length">
            <p>Прокомментируйте расхождение план/факт для:</p>
            <ul>
              <li *ngFor="let posm of posmsValuesNotMatch">
                {{ posm.name }}
              </li>
            </ul>
          </div>
          <textarea
            (input)="setCommentValidators()"
            fullWidth
            nbInput
            placeholder="Комментарий"
            formControlName="comment"
          ></textarea>
        </div>
        <button
          [disabled]="!form.valid"
          type="submit"
          nbButton
          class="submit-button"
          status="danger"
        >
          Подтвердить завершение
        </button>
      </form>
    </div>
  </nb-card-body>
</nb-card>
