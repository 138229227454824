<ngx-address-modal *ngIf="showModal" (setAddress)="setAddress($event)" (close)="showModal = false"></ngx-address-modal>
<nb-card [nbSpinner]="isLoading" nbSpinnerStatus="primary">
  <nb-card-header>{{actionText}} торговую точку</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label class="label">Торговая сеть</label>
            <ng-select
              class="select"
              [items]="retailChains"
              [clearable]="true"
              bindLabel="name"
              bindValue="id"
              notFoundText="Не найдено"
              placeholder="Торговая сеть"
              typeToSearchText="Напишите название торговой сети"
              loadingText="Поиск..."
              [loading]="loadingRetainChains"
              [typeahead]="searchRetailChainsInput$"
              [(ngModel)]="selectedRetailChain"
              [ngModelOptions]="{standalone: true}"
            ></ng-select>
          </div>

          <div class="form-group">
            <label for="name" class="label" requiredTag>Название торговой точки</label>
            <input [status]="errorsService.hasError(form.get('name')) ? 'danger' : ''"
                   id="name" type="text" nbInput fullWidth placeholder="Название торговой точки"
                   formControlName="name"/>
            <ngx-form-errors [control]="form.get('name')"></ngx-form-errors>
          </div>

          <div class="form-group">
            <label for="pointCode" class="label" requiredTag>Код точки</label>
            <input id="pointCode" type="text" nbInput fullWidth placeholder="Код точки" formControlName="pointCode"/>
          </div>

          <div class="form-group">
            <label for="externalCode" class="label">Внутренний код сети</label>
            <input id="externalCode" type="text" nbInput fullWidth placeholder="Внутренний код сети"
                   formControlName="externalCode"/>
          </div>

          <div class="form-group">
            <label for="city" class="label" requiredTag>Тарифная зона</label>
            <nb-select [(selected)]="selectedTariffZone" fullWidth id="tariff_zone"
                       placeholder="Тарифная зона">
              <nb-option *ngFor="let zone of tariffZones$ | async;" value="{{zone?.id}}">
                {{zone.zone}}
              </nb-option>
            </nb-select>
          </div>

          <div class="form-group">
            <label for="city" class="label" requiredTag>Регион Action</label>
            <nb-select [(selected)]="selectedCity"
                       fullWidth id="city"
                       placeholder="Регион Action">
              <nb-option *ngFor="let city of cities$ | async;" value="{{city.id}}">
                {{city.name}}
              </nb-option>
            </nb-select>
          </div>
          <div class="form-group">
            <label for="city" class="label" requiredTag>Регион клиента</label>
            <nb-select [(selected)]="selectedSecondaryCity" fullWidth id="city"
                       placeholder="Регион клиента">
              <nb-option *ngFor="let city of secondaryCities$ | async;" value="{{city.id}}">
                {{city.name}}
              </nb-option>
            </nb-select>
          </div>
          <div class="form-group">
            <label for="address" class="label" requiredTag>Адрес торговой точки</label>
            <div class="row">
              <div class="col-12 col-md-6">
                <input id="address" [status]="errorsService.hasError(form.get('addressName')) ? 'danger' : ''"
                       type="text" nbInput fullWidth placeholder="Адрес торговой точки"
                       formControlName="addressName"/>
                <ngx-form-errors [control]="form.get('addressName')"></ngx-form-errors>
              </div>
              <div class="col-12 col-md-2">
                <button class="show-map-button" (click)="showModal = true" nbButton type="button" status="primary">
                  Указать на карте
                </button>
              </div>
            </div>
          </div>
          <div class="table">
            <ngx-selected-posms-table *ngIf="table" [list]="table"></ngx-selected-posms-table>
          </div>

          <div *ngIf="(isAdmin$ | async) && clients$ | async as clients"
               class="form-group">
            <label for="role" class="label" requiredTag>Клиент</label>
            <div>
              <nb-select id="role" formControlName="client" placeholder="Выберите клиента">
                <nb-option *ngFor="let client of clients" value="{{client.id}}">
                  {{client.name}}
                </nb-option>
              </nb-select>
            </div>
          </div>

          <button [disabled]="
                    !form.valid
                    || !selectedTariffZone
                    || !selectedCity
                    || !selectedSecondaryCity
                    || !form.get('pointCode').value
                  "
                  type="submit"
                  nbButton
                  status="danger"
          >Сохранить
          </button>
          <button *ngIf="!isNew" style="margin-left: 10px;" (click)="removeTT()" nbButton status="warning">Удалить</button>
        </form>
      </div>
    </div>
  </nb-card-body>
</nb-card>
