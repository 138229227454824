import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MENU_ITEMS } from '../../../pages/pages-menu';
import { isMobileDevice } from '../../../../utils';
import { AuthService } from '../../../services/auth/auth.service';
import { UserResponse } from '../../../../types';
import { DOWNLOAD_ORDER_ANALYTICS_LINK } from '../../../pages/pages.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user = '';
  userRole = '';
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  currentTheme = 'default';
  userMenu = [{ title: 'Profile' }, { title: 'Выйти' }];
  pageTitle;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private layoutService: LayoutService,
              private authService: AuthService,
              private router: Router,
  ) {
  }

  private count = 0;
  private readonly rolesRu = {
    'COORDINATOR'      : 'Координатор',
    'SUPER_ADMIN'      : 'Cупер админ',
    'SENIOR_INSTALLER' : 'Старший установщик',
    'CLIENT'           : 'Клиент',
    'ACTION'           : 'Экшн',
    'INSTALLER'        : 'Установщик',
  }

  setTitleByUrl = url => {
    let key = '';

    if (url.includes('pages')) {
      key = url.split('/pages/')[1].split('/')[0];
    }

    const currentMenuItem = [...MENU_ITEMS, DOWNLOAD_ORDER_ANALYTICS_LINK].find(({ link }) =>
      link.includes(key));

    const defaultTitle = key === 'route' ? 'Маршруты' : 'Склады POSM';

    this.pageTitle = currentMenuItem ? currentMenuItem.title : defaultTitle;
  }

  ngOnInit() {
    this.setTitleByUrl(this.router.url);

    this.authService.user.pipe(filter(Boolean))
      .subscribe((u: UserResponse) => {
        const midName = u.middle_name ? u.middle_name.trim().slice(0, 1).toUpperCase() : ''
        this.user = `
          ${u.last_name} ${u.first_name.slice(0, 1).toUpperCase()}. ${midName ? midName + '.' : ''}
        `

        this.userRole = `(${this.rolesRu[u.roles[0]]})`
      })

    this.router.events.pipe(
      takeUntil(this.destroy$),
    ).subscribe(event => {
      if ((event as any).url) {
        this.setTitleByUrl((event as any).url);
      }
    });


    this.menuService.onItemClick()
      .subscribe(() => {
        if (isMobileDevice()) {
          this.sidebarService.toggle(false, 'menu-sidebar'); // Hide sidebar
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
