import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  Optional,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { Subscription, Observable, EMPTY, of } from 'rxjs';

import { CrudService } from '../../../../services/crud/crud.service';
import {
  BagItemWithBlankRun,
  Id, PosmWithCount,
  ResourceRoutePart,
  RoutePart,
  StoresResponse,
  WarehouseGetResponse
} from '../../../../../types';
import { ImageUploadService } from '../../../../services/ImageUploadService/image-upload.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { discrTranslate, getExchangeType, getEntityType, isMobileDevice, getLocation } from '../../../../../utils';
import { HttpErrorHandlerService } from '../../../../services/http-error-handler/http-error-handler.service';
import { isWarehouse } from '../../../../../utils/type-predicate';
import { FromDeviceService } from '../../../../services/fromDevice/from-device.service';
import { BlankRunComponent } from '../../../orders/components/blank-run/blank-run.component';
import { getPartType, EPartType } from './utils';


@Component({
  selector: 'ngx-route-part',
  templateUrl: './route-part.component.html',
  styleUrls: ['./route-part.component.scss'],
})
export class RoutePartComponent implements OnInit, OnDestroy {
  @ViewChild('openFile', { static: false }) openFileInput: ElementRef;

  public getPartName = discrTranslate
  public partType: EPartType
  public readonly EPartType = EPartType;

  public readonly partTypeEnum = EPartType

  public readonly actionNames: Record<EPartType, string> = {
    [EPartType.inDelivery]: 'Нужно доставить',
    [EPartType.outDelivery]: 'Нужно отгрузить',
    [EPartType.install]: 'Нужно смонтировать',
    [EPartType.uninstall]: 'Нужно демонтировать',
    [EPartType.repair]: 'Нужно отремонтировать',
  }

  public readonly getTypeFactVerb: Record<EPartType, string> = {
    [EPartType.inDelivery]: 'доставлено',
    [EPartType.outDelivery]: 'отгружено',
    [EPartType.install]: 'смонтировано',
    [EPartType.uninstall]: 'демонтировано',
    [EPartType.repair]: 'отремонтировано',
  }

  public cachedPart: RoutePart;

  public get hasUploadedFiles(): boolean {
    return Object.keys(this.files).length > 0
  }

  public modalId: number
  @HostBinding('class') mobileView = ''

  public isLoading = false;

  public subPosmsFields: Subscription;
  public subRoute: Subscription;
  private imageSub: Subscription;
  private modalSub: Subscription;

  public form: FormGroup;
  public routeId: number;

  public posms = [];
  public posmsValuesNotMatch = [];

  public currentPart: RoutePart;
  public title = '';
  public address = '';
  public entityCodes = '';

  public route$: Observable<ResourceRoutePart>

  public files: { [key: string]: File } = {};
  private canUseCamera = false
  public posmIdToBagItem = new Map<number, BagItemWithBlankRun>([])

  public isBlankRun(posmId: number): boolean {
    return this.posmIdToBagItem.get(posmId)?.blank_run
  }

  constructor(
    private fb: FormBuilder,
    private toastr: NbToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crudService: CrudService,
    private imageUploaderService: ImageUploadService,
    private fromDeviceService: FromDeviceService,
    private errorHandlerService: HttpErrorHandlerService,
    @Optional() private dialogRef: NbDialogRef<RoutePartComponent>,
    public authSvc: AuthService,
    private cdr: ChangeDetectorRef,
    private dialogService: NbDialogService,
  ) {
    this.form = this.fb.group({
      comment: [''],
      isFilesExist: [null, Validators.required],
      posmsFields: this.fb.array([]),
    });
  }

  public removeFile = (id: number) => {
    this.openFileInput.nativeElement.value = ''
    delete this.files[id];
    this.checkFilesExist();
  }

  public setBlankRun(posm: PosmWithCount): void {
    this.modalSub && this.modalSub.unsubscribe();
    this.modalSub = this.dialogService.open(
      BlankRunComponent, {
        context: {
          bagId: this.posmIdToBagItem.get(posm.id).id
        }
      }
    ).onClose.subscribe(confirmed => {
      if (confirmed) {
        this.updatePosmForBlankRun(posm)
      }
    })
  }

  private updatePosmForBlankRun(posm: PosmWithCount): void {
    const bagItem = this.posmIdToBagItem.get(posm.id)
    bagItem.blank_run = true
    this.posmIdToBagItem.set(posm.id, bagItem)

    const posmIdx = this.posms.findIndex(p => p.id === posm.id);
    if (posmIdx > -1) {
      const control = (this.form.get('posmsFields') as FormArray).controls[posmIdx]
      control.setValue({ count: 0 })
      control.get('count').clearValidators()
      control.get('count').updateValueAndValidity()

      this.posmsValuesNotMatch = this.posmsValuesNotMatch.filter(p => p.id !== posm.id)
    }
  }

  public closeModal(): void {
    this.dialogRef.close({ update: this.currentPart })
  }

  public checkFilesExist = () => {
    const value = Object.keys(this.files).length !== 0 ? 1 : null;
    this.form.controls['isFilesExist'].setValue(value);
  }

  public uploadFileWrapper(): void {
    const isMobile = isMobileDevice();
    if (isMobile && this.canUseCamera) {
      (window as any).openCamera();
    } else {
      this.openFileInput.nativeElement.click();
    }
  }

  public onFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      this.isLoading = true;
      this.imageUploaderService.uploadImage(event.target.files).subscribe(response => {
        if (response) {
          this.files = {
            ...this.files,
            [response.id]: event.target.files.item(0),
          };
          this.checkFilesExist();
        }
        this.isLoading = false;
      }, () => {
        this.toastr.danger('Не удалось загрузить файл', 'Упс!');
        this.isLoading = false;
      });
    }
  }

  public handleSuccess(response): void {
    if (response) {
      this.toastr.show('Сохранено', 'Ура!', { status: 'primary' });
      this.router.navigateByUrl('pages/route/my');
      if (this.modalId) {
        this.dialogRef.close({ modalId: this.modalId })
      }
    }

    this.isLoading = false;
  }

  public handleError(error): void {
    this.isLoading = false;

    if (error && error.status && error.status === 417) {
      this.errorHandlerService.showMessage(error.url, error.status, error.error.message);
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;

      let pos: Position
      try {
        pos = await getLocation();
      } catch (err) {
        console.error(err.message);
      }

      if (this.routeId) {
        let payload = this.mapFormValues(this.form)
        if (pos) {
          payload = {
            ...payload,
            end_lon: pos.coords.longitude,
            end_lat: pos.coords.latitude,
          }
        }
        this.crudService
          .create(payload, `/routes/parts/${this.routeId}/complete`)
          .subscribe({ next: this.handleSuccess.bind(this), error: this.handleError.bind(this) });
      }
    } else {
      this.toastr.danger('Не заполнены данные или не загружены фотографии', 'Ошибка!');
    }
  }

  private getQuantity(posmId: number, index: number): number {
    const fc = (this.form.get('posmsFields') as FormArray).controls[index]
    const value = (fc?.value?.count) || 0

    return this.isBlankRun(posmId) ? 0 : +value;
  }

  public mapFormValues(form: FormGroup): Record<any, any> {
    const images = Object.keys(this.files).map(key => ({ id: +key }));

    return {
      fact_quantity: this.posms.map(
        (posm, index) => ({
          posm: posm.id, fact: this.getQuantity(posm.id, index) || 0,
        })
      ),
      comment: form.get('comment').value,
      images: images,
    };
  }

  public setCommentValidators(): void {
    const validatos = [Validators.required, Validators.pattern(/\S/)]
    this.form.get('comment').setValidators(this.posmsValuesNotMatch.length > 0 ?  validatos : []);
    this.form.get('comment').updateValueAndValidity();
  }

  private getCodesString(entity: StoresResponse | WarehouseGetResponse): string {
    if (isWarehouse(entity)) {
      return entity.warehouse_code ? `[${entity.warehouse_code}] ` : ""
    } else {
      const pointCode = entity.point_code ? `[${entity.point_code}] ` : ""
      const externalCode = entity.external_code ? `(${entity.external_code}) ` : ""

      return `${pointCode}${externalCode}`
    }
  }

  private setCameraSubscriptions(): void {
    this.authSvc.useCameraForPhotos$().subscribe(use => this.canUseCamera = use)
    this.imageSub = this.fromDeviceService.deviceImage$.subscribe(imageBase64Data => {
      console.log('upload file from device', imageBase64Data);

      fetch('data:image/jpeg;base64,' + imageBase64Data.image)
        .then(res => res.blob())
        .then(blob => {
          console.log('fetch blob', blob);
          const filename = new Date().toLocaleDateString() + '-' + new Date().toLocaleTimeString() + '.jpeg';
          const file = new File([blob], filename, { type: "image/jpeg" });
          this.imageUploaderService.uploadImage(file).subscribe(response => {
            console.log('upload image to backend', response);
            if (response) {
              this.files = {
                ...this.files,
                [response.id]: file,
              };
            }
            this.isLoading = false;
            this.checkFilesExist();
            this.cdr.detectChanges()

          }, () => {
            this.toastr.danger('Не удалось загрузить файл', 'Упс!');
            this.isLoading = false;
            this.cdr.detectChanges()
          });
        });
    });
  }

  private init(): void {
    this.route$ = this.modalId
      ? of({ parts: [this.cachedPart] })
      : this.crudService
        .get<ResourceRoutePart>('/routes/parts/installer')
        .pipe(
          finalize(() => this.isLoading = false),
          catchError(() => {
            this.isLoading = false;
            return EMPTY;
          }),
        );

    this.subRoute = this.route$.pipe(
      mergeMap(route => {
        return this.activatedRoute.paramMap.pipe(
          map((params: ParamMap) => [params, route]),
        );
      }),
    ).subscribe(([params, route]: [ParamMap, ResourceRoutePart]) => {
      this.mobileView = this.modalId ? 'mobile-view' : ''
      const id = +params.get('id') || this.modalId

      if (id) {
        this.routeId = id;

        this.currentPart = route.parts.find(part => part.id === +id);
        this.partType = getPartType(this.currentPart.discr)

        console.log({ type: this.partTypeEnum[this.partType] });
        console.log(this.currentPart, 'this.currentPart');
        const entity: any = this.currentPart['warehouse'] || this.currentPart['store'];

        this.title = entity.name;
        this.address = entity.address_name;
        this.entityCodes = this.getCodesString(entity)

        this.posms = this.currentPart.bag.items.map(item => {
          this.posmIdToBagItem.set(item.posm.id, item)

          const bagFromEnity = entity.bag.items
            .find(bagItem => bagItem.posm.id === item.posm.id);

          return ({
            ...item.posm,
            count: this.isBlankRun(item.posm.id) ? 0 : item.quantity,
            availableCount: bagFromEnity ? bagFromEnity.quantity : 0,
          });
        });

        this.setPosmFieldControls()

        this.subPosmsFields = this.form.get('posmsFields').valueChanges.subscribe(values => {
          values.forEach(({ count }, index) => {
            if (this.isBlankRun(this.posms[index].id)) return

            const actualPosm = this.posms[index];
            const isNotSameCount = actualPosm && actualPosm.count !== count;
            const existNotMatchPosm = this.posmsValuesNotMatch.find(p => p.id === actualPosm.id);

            if (isNotSameCount) {
              if (!existNotMatchPosm) {
                this.posmsValuesNotMatch.push(actualPosm);
              }
            } else {
              this.posmsValuesNotMatch = this.posmsValuesNotMatch.filter(p => p.id !== actualPosm.id);
            }
            this.setCommentValidators()
          });
        });
      }
      this.form.updateValueAndValidity()
    });
  }

  public ngOnInit(): void {
    this.setCameraSubscriptions()
    this.init()
  }

  private setPosmFieldControls(): void {
    const controls = this.posms.map(({ count, id }) => {
      const isBlankRun = this.posmIdToBagItem.get(id).blank_run
      const validators = isBlankRun ? [] : [Validators.required, Validators.min(1), Validators.max(1000000)]

      return this.fb.group({ count: [count, validators] })
    }) || []

    this.form.setControl('posmsFields', this.fb.array(controls))
  }

  public ngOnDestroy(): void {
    this.posmIdToBagItem.clear()
    this.imageSub && this.imageSub.unsubscribe()
    this.subPosmsFields && this.subPosmsFields.unsubscribe();
    this.subRoute && this.subRoute.unsubscribe();
  }
}
