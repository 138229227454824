import {
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ImageUploadService } from '../../../services/ImageUploadService/image-upload.service';
import { PosmResponse } from '../../../../types';

@Pipe({
  name: 'getSrc',
  pure: true,
})
export class GetSrc implements PipeTransform {
  constructor(
    private authService: AuthService,
    private imageUploader: ImageUploadService,
  ) {}

  transform(value: number): any {
    return `${this.imageUploader.getPath(value)}?token=${this.authService.getToken()}`;
  }
}
