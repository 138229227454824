import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcceptExcelService {
  private _accept: Subject<any> = new Subject();

  get accept() {
    return this._accept.asObservable();
  }

  public setAccept = (accept: any) => {
    this._accept.next(accept);
  }

  constructor() { }
}
