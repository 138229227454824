<div class="row">
  <div class="col-12 label">Статус</div>
  <div class="col-12 value">
    <ngx-status-cell [value]="item.status"></ngx-status-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">ID Заявки</div>
  <div class="col-12 value">{{item.id}}</div>
</div>
<div class="row">
  <div class="col-12 label">Тип работ</div>
  <div class="col-12 value">
    <ngx-works-type-cell
      [value]="item.works_type"
      [rowData]="item"
    ></ngx-works-type-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Создатель</div>
  <div class="col-12 value">
    <ngx-created-by-cell
      [value]="item.created_by_user"
      [rowData]="item"></ngx-created-by-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Город</div>
  <div class="col-12 value">
    <ngx-city-cell
      [value]="item.city_name"
      [rowData]="item"></ngx-city-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Дата создания</div>
  <div class="col-12 value">
    <ngx-created-at-cell [value]="item.created_at" [rowData]="item"></ngx-created-at-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Рекомендуемая дата выполнения</div>
  <div class="col-12 value">{{item.deadline}}</div>
</div>
<div class="row">
  <div class="col-12 label">Торговая точка</div>
  <div class="col-12 value">
    <ngx-address-cell
      [value]="item.store"
    ></ngx-address-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Исполнитель</div>
  <div class="col-12 value">
    <ngx-executor-cell [value]="item.executor" [rowData]="item"></ngx-executor-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Назначенная дата выполнения</div>
  <div class="col-12 value">
    <ngx-date-cell [value]="item.planned_at"></ngx-date-cell>
  </div>
</div>
<div class="row">
  <div class="col-12 label">Фактическая дата выполнения</div>
  <div class="col-12 value">
    <ngx-date-cell [value]="item.executed_at"></ngx-date-cell>
  </div>
</div>
<div class="row buttons">
  <div class="col-6 text-left">
    <button outline status="danger" nbButton (click)="handleGoToDetail()">
      Подробнее
    </button>
  </div>
</div>
