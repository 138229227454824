import { Component, OnInit, Input } from '@angular/core';
import { OrderResponse, Bag } from '../../../../../types';

@Component({
    selector: 'ngx-works-type-cell',
    template: `{{renderValue}}`,
  })
export class WorksTypeCellComponent implements OnInit {
    private translations = {
      'install_bag': 'монтаж',
      'uninstall_bag': 'демонтаж',
      'repair_bag': 'ремонт',
      'is_delivery': 'доставка',
    };

    renderValue = '';

    @Input()
    value: string;

    @Input()
    rowData: OrderResponse;

    constructor() { }

    getName = (bag: Bag, key: string) => bag.items && bag.items.length > 0 ? this.translations[key] : null;

    ngOnInit() {
      const {
        install_bag,
        uninstall_bag,
        repair_bag,
        is_delivery,
        } = this.rowData;

      this.renderValue = [
        this.getName(install_bag, 'install_bag'),
        this.getName(uninstall_bag, 'uninstall_bag'),
        this.getName(repair_bag, 'repair_bag'),
        is_delivery ? this.translations['is_delivery'] : null,
      ]
        .filter(value => value)
        .join(', ');
    }
  }
