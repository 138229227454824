import { Injectable } from '@angular/core';
import { DeviceNotification, PushNotificationRequest, TypeMessageFromDevice } from '../../../types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { getValueFormPushMessage } from '../../../utils';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PushService {
  _timeoutID: number;
  _timeout: Subject<string> = new Subject<string>();

  constructor(
    private http: HttpClient,
  ) { }

  get timeout() {
    return this._timeout.asObservable();
  }

  sendPushNotification(device: DeviceNotification, pushMessage: string) {
    window.clearTimeout(this._timeoutID);

    this.http.post<PushNotificationRequest>(`${API_URL}/users/token`, {
      device,
      token: getValueFormPushMessage(pushMessage),
    }).subscribe();
  }

  sendGeoLocation(pushMessage: string) {
    window.clearTimeout(this._timeoutID);
  }

  startTimer(type: TypeMessageFromDevice) {
    window.clearTimeout(this._timeoutID);

    this._timeoutID = window.setTimeout(() => {
      const errorMessage = type === 'push' ?
      'У вас не будут показываться пуш уведомления, так как вы не включили их в настройках' :
      null;
      // 'Ой, у вас не работает геолокация. Пожалуйста включите ее в настройках';

      this._timeout.next(errorMessage);
    }, 5000);
  }
}
