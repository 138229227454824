import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandlerService } from '../services/http-error-handler/http-error-handler.service';
import { parseUrl } from '../../utils';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import { EServerErrors } from '../../types/EServerErrors';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private httpErrHandler: HttpErrorHandlerService,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpErrorResponse | any> {
    const { status } = error;
    const errorMsg  = error && error.error && error.error.message;
    const isUserNotAuthenticated = status === 401;
    const parsedUrl = parseUrl(request.url);
    const url = parsedUrl && parsedUrl.pathname || "";
    const isV2Request = url.includes('/v2/api')

    if (isV2Request && status === EServerErrors.badRequest) {
      this.httpErrHandler.showV2Server400Error(error?.error, url)
      return throwError(error)
    }

    if (status && !isUserNotAuthenticated && url) {
      this.httpErrHandler.showMessage(url, status, errorMsg);
    }

    if (isUserNotAuthenticated && url.includes('login')) {
      this.httpErrHandler.showMessage(url, status);
    }

    if (isUserNotAuthenticated) {
      this.router.navigateByUrl('/auth/login').then();
    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    return next.handle(request).pipe(catchError(error => this.handleError(error, request)))
  }
}
