import { Component, OnInit, Input } from '@angular/core';
import { CrudService } from '../../../services/crud/crud.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { of } from 'rxjs';
import moment from 'moment';
import { ResourceRoutePart } from '../../../../types';
import { map, switchMap } from 'rxjs/operators';
import { AcceptExcelService } from '../../../services/accept-excel/accept-excel.service';
import { actionsTranslate, mapDate } from '../../../../utils/index';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-shared-route-view',
  templateUrl: './shared-route-view.component.html',
  styleUrls: ['./shared-route-view.component.scss'],
})
export class SharedRouteViewComponent implements OnInit {
  tranlsate = actionsTranslate;

  @Input()
  public routeData;

  @Input()
  public isModal: boolean;

  letters = 'abcdefghijklmnopqrstuvwxyz';

  isLoading = true;

  date;
  min = moment();
  max = moment().add(1, 'month');
  dateErrorMessage;

  route$ = this.route.paramMap.pipe(
   switchMap((params: ParamMap) => {
      if (params.get('id')) {
        return this.crudService.get<ResourceRoutePart>(`/routes/${params.get('id')}`);
      }

      return of(this.routeData);
    }),
    map(response => {
      this.isLoading = false;

      if (response) {
        console.log(response, 'response');
        return response;
      }

      return response;
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private crudService: CrudService,
    private acceptExcelService: AcceptExcelService,
    private toastr: NbToastrService,
  ) {
  }

  public handleAccept = () => {
    if (!this.date) {
      this.dateErrorMessage = 'Укажите дату';
      return;
    }

    this.acceptExcelService.setAccept({
      accept: true,
      date: this.date,
    });
    (document.querySelector('.overlay-backdrop') as HTMLDivElement).click();
  }

  public handleDateChange = value => {
    const date = moment(value);
    const now = moment().subtract(1, 'days');

    if (now > date) {
      this.dateErrorMessage = 'Дата не может быть раньше, чем сегодня';
      this.date = null;
      return;
    }

    this.dateErrorMessage = null;

    this.date = mapDate(value);
  }

  public handleDecline = () => {
    this.acceptExcelService.setAccept({
      accept: false,
      date: this.date,
    });
    (document.querySelector('.overlay-backdrop') as HTMLDivElement).click();
  }

  ngOnInit() {
  }

}
